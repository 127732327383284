import { createApp, h } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import { QuillEditor } from "@vueup/vue-quill";
import Vue3Toastify from "vue3-toastify";
import router from "./router/index.js";
import "./index.css";
import "ant-design-vue/dist/reset.css";
import "vue-lite-youtube-embed/style.css";
import "vue3-carousel/dist/carousel.css";
import "vue3-toastify/dist/index.css";
import "aos/dist/aos.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import "filepond/dist/filepond.min.css";
import { TableModule } from 'quill-table';
import QuillTableUI from 'quill-table-ui'; // quill-table 플러그인
import 'quill-table-ui/dist/index.css'; // 테이블 UI 스타일
import Quill from "quill";

Quill.register({
  'modules/table': TableModule,
  'modules/tableUI': QuillTableUI,
});

const app = createApp({
  render() {
    return h(App);
  },
});
// app.config.errorHandler = (err, instance, info) => {
//   console.error('에러가 발생했습니다:', err);
//   // 에러 발생 시 특정 페이지로 이동
//   router.push({ name: 'Error' });
// };

app.config.errorHandler = (err) => {
  console.error('에러가 발생했습니다:', err);
  // 에러 발생 시 에러페이지로 이동
  router.push({ name: 'Error' });
};

app.component("QuillEditor", QuillEditor);
app.use(QuillEditor, {
  modules: {
    table: true
  }
});
app.use(Antd);
app.use(Vue3Toastify, { autoClose: 3000 });
app.use(router);
app.mount("#app");
